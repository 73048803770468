<template>
  <v-app class="web">

    <logo-bar />

    <app-bar :drawer="false" @change="drawerAlter(el)" />
    
    <view-web />

    <Footer/>

  </v-app>
</template>

<script>
export default {
  name: "Layout",
  components: {
    LogoBar: () => import("@/layouts/web/commons/LogoBar"),
    AppBar: () => import("@/layouts/web/commons/AppBar"),
    ViewWeb: () => import("@/layouts/web/commons/ViewWeb"),
    Footer: () => import("@/layouts/web/commons/Footer.vue")
  }
};
</script>

<style lang="sass">

  .web
    background-color: #f5f5f5
</style>